import { Component, For } from 'solid-js'
import styles from '~/components/widget-collaborators/widget-collaborators.module.scss'
import FavoriteIcon from '~/assets/icons/favorite.svg'
import CollaboratorEntry from '~/components/collaborator-entry/collaborator-entry'
import { deleteContact } from '~/services/database/contacts'
import { UserEntry } from '~/components/dashboard-collaborators/dashboard-collaborators.interfaces'
import { Role } from '~/services/database/permissions'
import { WidgetCollaboratorsProps } from '~/components/widget-collaborators/widget-collaborators.interfaces'

const WidgetCollaborators: Component<WidgetCollaboratorsProps> = ({ contacts, refetchContacts }) => {
  // TODO: Add alpha sort and a toggle button in the UI
  // const sortBy = (contacts: UserEntry[] = [], method: 'role' | 'alpha') => {
  const sortBy = (contacts: UserEntry[] = []) => {
    return contacts.sort((a, b) => {
      const aValue = new Role(a.meta?.preferred_role).index
      const bValue = new Role(b.meta?.preferred_role).index
      return aValue - bValue
    })
  }

  const onContactDelete = (contactId: string) => async () => {
    deleteContact({ contactId }).then(() => {
      refetchContacts()
    })
  }

  return (
    <div class={styles.widgetCollaborators}>
      <img src='/assets/images/illustration-collaborators.png' class={styles.illustration} />

      <div class={styles.title}>
        Partenaires
      </div>

      <div class={styles.collaboratorsList}>
        <div class={styles.sectionTitle}>
          <FavoriteIcon class={styles.icon} />
          <div class={styles.text}>
            <div class={styles.minor}>
              Votre liste de
            </div>
            <div class={styles.major}>
              Favoris
            </div>
          </div>
        </div>

        <div class={styles.list}>
          <For each={sortBy(contacts)} fallback={(
            <div class={styles.emptyFavorites}>Aucun favori pour l'instant</div>
          )}>
            {entry => (
              <CollaboratorEntry
                user={entry.meta}
                actionLabel='Enlever des favoris'
                onClick={onContactDelete(entry.contact_id)}
              />
            )}
          </For>
        </div>
      </div>
    </div>
  )
}

export default WidgetCollaborators
