import { useLocation } from '@solidjs/router'
import { createSignal } from 'solid-js'
import AccountPartnersAdd from '~/components/account-partners-add/account-partners-add'
import AccountPasswordSet from '~/components/account-password/account-password'
import AccountSetupDone from '~/components/account-setup-done/account-setup-done'
import Landing from '~/components/landing/landing'
import OnboardingLayout from '~/components/onboarding-layout/onboarding-layout'
import { OnboardingStep } from '~/components/onboarding-layout/onboarding-layout.interfaces'
import { translations } from '~/translations'
import { OnboardingState } from '~/types/routes/onboarding'

export default function Onboarding() {
  const { state } = useLocation<OnboardingState>()

  const nextStep = () => {
    setSteps(steps => {
      const nextIndex = (steps.findIndex(entry => entry.current) ?? 0) + 1
      return steps.map((step, index) => ({
        ...step,
        current: index === nextIndex
      }))
    })
  }

  const initialSteps: OnboardingStep[] = []
  if(state?.mustCreatePassword){
    initialSteps.push({
      name: translations().onboarding.createPassword,
      current: true,
      component: <AccountPasswordSet onSuccess={nextStep} />
    })
  }
  initialSteps.push({
    name: translations().onboarding.invitePeople,
    current: state?.mustCreatePassword ? false : true,
    component: <AccountPartnersAdd onSuccess={nextStep} />
  },
  {
    name: translations().onboarding.done,
    current: false,
    component: <AccountSetupDone />
  })

  const [steps, setSteps] = createSignal(initialSteps)
  const currentStep = () => steps().find(step => step.current)?.component

  return (
    <Landing onboarding>
      <OnboardingLayout steps={steps()}>
        {currentStep()}
      </OnboardingLayout>
    </Landing>
  )
}
