import { For, ParentComponent } from 'solid-js'
import { OnboardingLayoutProps } from '~/components/onboarding-layout/onboarding-layout.interfaces'
import styles from '~/components/onboarding-layout/onboarding-layout.module.scss'
import { translations } from '~/translations'

const OnboardingLayout: ParentComponent<OnboardingLayoutProps> = ({ children, steps }) => {
  const stepClasses = (current: boolean) => ({
    [styles.step]: true,
    [styles.current]: current
  })
  
  return (
    <div class={styles.onboardingLayout}>
      <div class={styles.welcome}>
        {translations().onboarding.welcome}
      </div>

      <div class={styles.steps}>
        <For each={steps}>
          {(step, index) => (
            <div classList={stepClasses(step.current)}>
              <div class={styles.index}>{index()+1}</div>
              <div class={styles.label}>{step.name}</div>
            </div>
          )}
        </For>
      </div>

      <div class={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default OnboardingLayout
