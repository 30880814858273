import { useNavigate } from '@solidjs/router'
import { Component } from 'solid-js'
import styles from '~/components/account-setup-done/account-setup-done.module.scss'
import Button from '~/components/button/button'
import { editUserSettings } from '~/services/database/users'
import { translations } from '~/translations'

const AccountSetupDone: Component = () => {
  const navigate = useNavigate()
  const toDashboard = () => {
    editUserSettings({
      onboardingComplete: true
    })
    navigate('/')
  }

  return (
    <div class={styles.accountSetupDone}>
      <div class={styles.illustrationContainer}>
        <img
          class={styles.illustration}
          src='/assets/images/illustration-collaboration.png'
          alt=''
        />
      </div>

      <aside class={styles.text}>
        <div class={styles.title}>
          {translations().onboarding.welcome1}
        </div>
        <div class={styles.description}>
          {translations().onboarding.welcome2}
        </div>
        <Button
          onClick={toDashboard}
          style='lagoon'
        >
          {translations().onboarding.begin}
        </Button>
      </aside>
    </div>
  )
}

export default AccountSetupDone
