import { Component, createResource } from 'solid-js'
import { AccountPartnersAddProps } from '~/components/account-partners-add/account-partners-add.interfaces'
import { getContacts } from '~/services/database/contacts'
import styles from '~/components/account-partners-add/account-partners-add.module.scss'
import Button from '~/components/button/button'
import WidgetCollaborators from '~/components/widget-collaborators/widget-collaborators'
import AccountPartnersAddForm from '~/components/account-partners-add/account-partners-add-form'
import { translations } from '~/translations'

const AccountPartnersAdd: Component<AccountPartnersAddProps> = ({ onSuccess }) => {
  const [contacts, { refetch: refetchContacts }] = createResource(getContacts)

  const onContactAdd = () => {
    refetchContacts()
  }

  return (
    <div class={styles.accountPartnersAdd}>
      <div class={styles.main}>
        <WidgetCollaborators
          contacts={contacts.latest ?? []}
          refetchContacts={refetchContacts}
        />

        <AccountPartnersAddForm
          contacts={contacts.latest ?? []}
          onSuccess={onContactAdd}
        />
      </div>
      
      <footer class={styles.navigation}>
        <Button
          onClick={onSuccess}
        >
          {translations().general.actions.next}
        </Button>
      </footer>
    </div>
  )
}

export default AccountPartnersAdd
