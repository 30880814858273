import { forward, InferOutput, minLength, object, partialCheck, pipe, string } from 'valibot'

export const SetPasswordSchema = pipe(
  object({
    password: pipe(string(), minLength(6)),
    passwordConfirmation: pipe(string(), minLength(6))
  }),
  forward(
    partialCheck(
      [['password'], ['passwordConfirmation']],
      (input) => input.password === input.passwordConfirmation,
      'Mot de passes différents !'
    ),
    ['passwordConfirmation']
  )
)

export type AccountPasswordSetType = InferOutput<typeof SetPasswordSchema>

export interface AccountPasswordSetProps{
  onSuccess: () => void
}