import { Component } from 'solid-js'
import { SubmitHandler, createForm, setResponse, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/styles/simple-form.module.scss'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { editUserSettings, resetPassword } from '~/services/database/users'
import { SetPasswordSchema, AccountPasswordSetType, AccountPasswordSetProps } from '~/components/account-password/account-password.interfaces'
import { reportError } from '~/services/errors'
import FormError from '~/components/form-error/form-error'
import { translations } from '~/translations'

const AccountPasswordSet: Component<AccountPasswordSetProps> = ({ onSuccess }) => {
  const [form, { Form, Field }] = createForm<AccountPasswordSetType>({
    validate: valiForm(SetPasswordSchema)
  })
  const onSubmit: SubmitHandler<AccountPasswordSetType> = async (values) => {
    const result = await resetPassword({
      password: values.password
    })
    if (result.error) {
      const message = `
        ${result.error.name}:\n
        ${result.error.message}\n
      `
      reportError(result.error)
      setResponse(form, {
        status: 'error',
        message
      })
    } else {
      editUserSettings({
        mustCreatePassword: false
      })
      onSuccess()
    }
  }

  return (
    <div class={styles.container}>
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name="password">
          {(field, props) => (
            <TextField
              label={translations().register.password}
              type='password'
              autocomplete='new-password'
              {...field}
              {...props}
            />
          )}
        </Field>

        <Field name="passwordConfirmation">
          {(field, props) => (
            <TextField
              label={translations().register.confirmPassword}
              type='password'
              autocomplete='new-password'
              {...field}
              {...props}
            />
          )}
        </Field>

        <FormError response={form.response} />

        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().general.actions.send}
        </Button>
      </Form>
    </div>
  )
}

export default AccountPasswordSet
